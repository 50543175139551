
.theme-slider{
  .swiper-nav{
    margin-top: 0;
    cursor: pointer;
    [class*=swiper-] {
      opacity: 0;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      transition: opacity 0.4s ease-in-out;
      font-size: map-get($font-sizes, 2);
      color: var(--#{$variable-prefix}black);
      z-index: 1;
      margin: 0;
      @include hover-focus {
        color: var(--#{$variable-prefix}black);
        opacity: 0.8 !important;
      }
    }
    .swiper-button-prev{ 
      left: map_get($spacers, 0);
      transition: $transition-base;
    }
    .swiper-button-next{ 
      right: map_get($spacers, 0);
      transition: $transition-base;
    }
  }
  
  @include hover-focus { 
    .swiper-nav { 
      [class*=swiper-] {
         opacity: 0.5; 
        } 
    } 
  }

  @include hover-focus {
    .swiper-nav {
      .swiper-button-prev{ 
        left: map_get($spacers, 2);
      }

      .swiper-button-next{
        right: map_get($spacers, 2);
      }
    }
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: none;
  }
}

.news-slider{
  .swiper-pagination-bullet{
    width: 0.625rem;
    height: 0.625rem;
    background: #D6D6D6;
    margin: 0.313rem 0.438rem !important;
    opacity: 1;

    @include hover-focus {
      background: $primary;
    }

    &.swiper-pagination-bullet-active{
      background: $primary;
    }
  }

  .swiper-horizontal>.swiper-pagination-bullets, 
  .swiper-pagination-bullets.swiper-pagination-horizontal, 
  .swiper-pagination-custom, 
  .swiper-pagination-fraction{
    bottom: 0;
  }
}

.header-classic{
  height: 40.438rem;

  @include media-breakpoint-up(sm) {
    min-height: 32.313rem;
    height: auto;
  }
}


