/*-----------------------------------------------
|   Pre-loader
-----------------------------------------------*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background-color: var(--#{$variable-prefix}primary);
  opacity: 1;
  transition: opacity .9s cubic-bezier(.77, 0, .18, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.loaded{
    opacity: 0;
  }
}

.loader-box {
	overflow-x: hidden;
	border-radius: 2px;
	width: 220px;
	.loader {
		height: 2px;
		border-radius: 2px;
		width: 100%;
		background: white;
		animation-timing-function: cubic-bezier(0.77, 0, 0.18, 1);
		animation: loading 0.8s infinite;
	}
}

@keyframes loading {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}



