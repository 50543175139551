/*-----------------------------------------------
|   Icons group
-----------------------------------------------*/
.icon-group {
  display: flex;
  .icon-item:not(:last-child) {
    margin-right: map_get($spacers, 2);
  }
}
.icon-item {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--#{$variable-prefix}700);
  transition: $transition-base;
  height: 2.5rem;
  width: 2.5rem;
  border: 0;
  position: relative;
  font-size: $font-size-sm;
  @include hover-focus {
    background-color: var(--#{$variable-prefix}200);
  }

  &.icon-item-sm {
    height: 1.875rem;
    width: 1.875rem;
  }
  
  &.icon-item-lg {
    height: 3.125rem;
    width: 3.125rem;
  }
}


.fa-icon-wait{
  opacity: 0;
  .fontawesome-i2svg-active &{
    opacity: 1;
  }
}


.ring-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  background: map-get($grays, '100');
  height: 6.25rem;
  width: 6.25rem;
  border-radius: 50%;
  border: 1px solid $border-color;
  font-size: map-get($font-sizes, 3);
  color: $primary;
}

.brand-icon{
  background: $primary;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  height: 2.5rem;
  width: 2.5rem;
  color: map-get($grays, 'white');
}